import React from 'react';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image'

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Layout from '../../components/layout';

const BlogPage = ({ data, pageContext }) => {

  return (
    <Layout title="Blog" heroTitle="Unsere Updates" heroSubtitle="Zu aktuellen Themen" imageData={data.heroImage.childImageSharp.gatsbyImageData} pageContext={pageContext}>
      <section className="section">
        <div className="container">
          
          <h1 className="title">Alle Beiträge</h1>
          {
            data.allMdx.nodes.map((node) => (
              <div className="columns">
                <div className="column is-one-third is-hidden-mobile">
                  <Link to={`/${node.slug}`}>
                    <BackgroundImage
                      Tag="div"
                      style={{height: "100%", filter: "saturate(0.5)"}}
                      {...convertToBgImage(getImage(node.frontmatter.hero_image))}
                    >
                    </BackgroundImage>
                  </Link>
                </div>
                <div className="column">
                  <Link className="subtitle has-text-primary is-size-5" to={`/${node.slug}`}>
                  <h2 className="mb-1">
                    {node.frontmatter.title}
                    </h2>
                  </Link>
                  <p className="has-text-grey">{node.frontmatter.date}</p>
                  <p className="is-hidden-tablet">
                    <Link to={`/${node.slug}`}>
                      <BackgroundImage
                        Tag="div"
                        className="is-flex is-flex-direction-column is-justify-content-space-between"
                        style={{padding: "1.5rem 1.5rem 1.5rem 1.5rem", height: "8rem", filter: "saturate(0.5)"}}
                        {...convertToBgImage(getImage(node.frontmatter.hero_image))}
                      >
                        <div className="is-align-self-flex-end" style={{margin:"-0.15rem", marginTop: "auto", textAlign:"right"}}>
                          {node.frontmatter.tags.map((tag) => (
                            <Link to={`/blog/tags/${tag}/`}>
                              <span className="tag is-uppercase is-light" style={{margin:"0.15rem"}}>{tag}</span>
                            </Link>
                           ))}
                         </div>
                      </BackgroundImage>
                    </Link>
                  </p>
                  <p className="mb-0">{node.excerpt}</p>
                  {/*<Link className="button" to={`/${node.slug}`}>Weiterlesen <span className="triangle-right"></span></Link>*/}
                  <p className="mt-4 is-hidden-mobile">
                    {node.frontmatter.tags.map((tag) => (
                      <Link to={`/blog/tags/${tag}/`} className="">
                        {' '}<span className="tag is-uppercase">{tag}</span>
                      </Link>
                    ))}
                  </p>
                </div>
              </div>
            ))
          }
    
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`{
  heroImage: file(relativePath: {eq: "blog.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allMdx(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {frontmatter: {templateKey: {eq: "blog-page"}}}
  ) {
    nodes {
      frontmatter {
        title
        date(formatString: "DD. MMMM YYYY", locale: "de-DE")
        tags
        authors {
          frontmatter {
            partnerName
          }
        }
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 3,
              width: 600,
              transformOptions: {
                cropFocus: CENTER
              }
            )
          }
        }
      }
      id
      slug
      excerpt(pruneLength: 200)
    }
  }
  placeholderImage: file(relativePath: {eq: "header-blog.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH)
    }
  }
}
`

export default BlogPage;
